@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap");

@font-face {
  font-family: "Noto Sans JP", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100&display=swap");
  font-weight: 100;
}

@font-face {
  font-family: "Noto Sans JP", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans JP", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
  font-weight: 500;
}

@font-face {
  font-family: "Noto Sans JP", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap");
  font-weight: 700;
}

body {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

html,
body {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.White-Smoke {
  background-color: #f6f6f6;
}
.Dark-Jungle-Green {
  background-color: #212121;
}
.Linen {
  background-color: #ffefe3;
}
.Blaze-Orange {
  background-color: #ff6f00;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track styles */
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 9999px;
  background-color: #aaaaaa;
  border-left: 4px rgba(255, 255, 255, 0) solid !important;
  background-clip: padding-box !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.auth {
  /* background-image: url("./Assets/Images/rings.png"); */
  background-image: url("./Assets/Images/value-file1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 767px) {
  .auth{
    background-image: unset;
    background-size: unset;
    background-repeat: unset;
    background-position: unset;
  }
}
.login {
  box-shadow: 0px 40px 100px 0px rgba(68, 68, 79, 0.1);
}
/* ---- Header--- */
.header-shadows {
  box-shadow: 0 1px 4 #57585c;
}
/* ---sidebar--- */
.dot {
  height: 10px !important;
  width: 10px !important;
  border-radius: 50% !important;
  display: inline-block !important;
  background: linear-gradient(to right, #0956ff, #5389ff);
}
.time-line {
  position: relative;
  border-left: 1px dashed #0956ff;
  height: inherit; /* Ensure the height covers the entire container */
}
.time-line::before,
.time-line::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #0956ff;
  border-radius: 50%;
  left: 60%;
  transform: translateX(-60%);
}
.time-line::before {
  top: 0;
}
.time-line::after {
  bottom: 0;
}

.time-line2 {
  position: relative;
  border-left: 1px dashed #0956ff;
  height: inherit; /* Ensure the height covers the entire container */
}
.time-line2::before,
.time-line2::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #0956ff;
  border-radius: 50%;
  left: 60%;
  transform: translateX(-60%);
}
.time-line2::before {
  top: 0;
}
.blue-time-line {
  position: relative;
  border-left: 1px dashed #0956ff;
  height: inherit; /* Ensure the height covers the entire container */
}
.blue-time-line::before,
.blue-time-line::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #0956ff;
  border-radius: 50%;
  left: 60%;
  transform: translateX(-60%);
}
.blue-time-line::before {
  top: 0;
}
.blue-time-line::after {
  bottom: 0;
}

.blue-time-line2 {
  position: relative;
  border-left: 1px dashed #0956ff;
  height: inherit; /* Ensure the height covers the entire container */
}
.blue-time-line2::before,
.blue-time-line2::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #0956ff;
  border-radius: 50%;
  left: 60%;
  transform: translateX(-60%);
}
.blue-time-line2::before {
  top: 0;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(33, 33, 33, 0.7);
  z-index: 49;
}

.sidebar-position {
  position: sticky;
  top: 78px;
  /* top: 67.5px; */
  /* height: calc(100vh - 67.5px); */
  /* overflow-y: auto;  */
}

/* *********************************** */
/* .div {
  height: 50px;
  position: relative;
  width: 20px;
  border-left: none;
}
.div:before {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background: white;
  display: inline-block;
}
.div:after {
  content: "";
  position: absolute;
  bottom: 6.1px;
  right: -3.45px;
  width: 20px;
  height: 1px;
  transform: rotate(135deg);
}
.button-cut {
  height: 60px;
  position: relative;
  width: 20px;
  border-left: none;
}
.button-cut:before {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background: white;
  display: inline-block;
}
.button-cut:after {
  content: "";
  position: absolute;
  bottom: 6.1px;
  right: -3.45px;
  width: 20px;
  height: 1px;
  transform: rotate(135deg);
}  */
/* *********************************** */
input[type="checkbox"] {
  accent-color: #0956ff;
}
input[type="checkbox"]:checked::before {
  content: "\2714";
  display: block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 19px;
  text-shadow: none !important;
  color: white !important;
  background-color: #0956ff;
}
.registration input[type="checkbox"] {
  accent-color: #0956ff;
}
.registration input[type="checkbox"]:checked::before {
  content: "\2714";
  display: block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 19px;
  text-shadow: none !important;
  color: white !important;
  background-color: #0956ff;
}
.registration input[type="radio"] {
  accent-color: #0956ff;
}
:root {
  accent-color: #0956ff;
}
/* Note: Size in Safari will be improved but smaller than results in other browsers */
[type="radio"] {
  font-size: inherit;
  width: 20px;
  height: 20px;
}

/* Month drop-down */
.react-datepicker__input-container .fulldate {
  height: 49px !important;
  border: 1px solid #ced0d8;
  border-right: none !important;
  padding: 16px 12px 16px 12px !important;
}
.react-datepicker__input-container .picker {
  width: 57px !important;
  height: 48px !important;
  border: 1px solid #ced0d8;
  padding: 16px 12px 16px 12px !important;
}
.react-datepicker {
  border-color: #212121 !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  box-shadow: 0 7px 22px 0 rgba(0, 0, 0, 0.4) !important;
}
.react-datepicker .react-datepicker__year-text,
.react-datepicker .react-datepicker__month-text {
  padding: 10px !important;
  width: 5rem !important;
}
.react-datepicker .react-datepicker__year-text--selected,
.react-datepicker .react-datepicker__month-text--selected,
.react-datepicker .react-datepicker__day--selected {
  background-color: #ff6f00 !important;
}
.react-datepicker .react-datepicker__header {
  background-color: #ffefe3 !important;
}
.react-datepicker .react-datepicker__year-wrapper {
  justify-content: space-around !important;
}

/* --- Loader--- */
.loader_line {
  height: 9px;
  width: 200px;
  --c: no-repeat linear-gradient(#000 0 0);
  background: var(--c), var(--c), var(--c), var(--c);
  background-size: 26% 3px;
  animation: l3 1s infinite;
}
@keyframes l3 {
  0%,
  10% {
    background-position: calc(0 * 100% / 3) 100%, calc(1 * 100% / 3) 100%,
      calc(2 * 100% / 3) 100%, calc(3 * 100% / 3) 100%;
  }
  17.5% {
    background-position: calc(0 * 100% / 3) 0, calc(1 * 100% / 3) 100%,
      calc(2 * 100% / 3) 100%, calc(3 * 100% / 3) 100%;
  }
  25% {
    background-position: calc(0 * 100% / 3) 0, calc(1 * 100% / 3) 0,
      calc(2 * 100% / 3) 100%, calc(3 * 100% / 3) 100%;
  }
  32.5% {
    background-position: calc(0 * 100% / 3) 0, calc(1 * 100% / 3) 0,
      calc(2 * 100% / 3) 0, calc(3 * 100% / 3) 100%;
  }
  40%,
  60% {
    background-position: calc(0 * 100% / 3) 0, calc(1 * 100% / 3) 0,
      calc(2 * 100% / 3) 0, calc(3 * 100% / 3) 0;
  }
  67.5% {
    background-position: calc(0 * 100% / 3) 100%, calc(1 * 100% / 3) 0,
      calc(2 * 100% / 3) 0, calc(3 * 100% / 3) 0;
  }
  75% {
    background-position: calc(0 * 100% / 3) 100%, calc(1 * 100% / 3) 100%,
      calc(2 * 100% / 3) 0, calc(3 * 100% / 3) 0;
  }
  82.5% {
    background-position: calc(0 * 100% / 3) 100%, calc(1 * 100% / 3) 100%,
      calc(2 * 100% / 3) 100%, calc(3 * 100% / 3) 0;
  }
  90%,
  100% {
    background-position: calc(0 * 100% / 3) 100%, calc(1 * 100% / 3) 100%,
      calc(2 * 100% / 3) 100%, calc(3 * 100% / 3) 100%;
  }
}

.custom-input {
  border: 1px solid;
  border-image: linear-gradient(to right, #f97316, #4f46e5) 1;
  color: #333;
}
.custom-border {
  position: absolute;
  width: 30px;
  height: 32.5px;
  transform: rotate(214deg);
  z-index: 9999;
  right: -4.3%;
  bottom: -21%;
  border-right: 1px solid;
  border-image: linear-gradient(to right, #f97316, #4f46e5) 1;
  background: #fff;
}
.custom-border-pass {
  position: absolute;
  width: 30px;
  height: 32.5px;
  transform: rotate(214deg);
  z-index: 9999;
  right: -4.3%;
  bottom: -21%;
  border-right: 1px solid #ced0d8;
  background: #fff;
}

@media only screen and (max-width: 1024px) {
  .custom-border {
    position: absolute;
    width: 30px;
    height: 32.5px;
    transform: rotate(226deg);
    z-index: 9999;
    right: -4.3%;
    bottom: -29%;
    border-right: 1px solid;
    border-image: linear-gradient(to right, #f97316, #4f46e5) 1;
    background: #fff;
  }
  .custom-border-pass {
    position: absolute;
    width: 30px;
    height: 32.5px;
    transform: rotate(226deg);
    z-index: 9999;
    right: -4.3%;
    bottom: -29%;
    border-right: 1px solid #ced0d8;
    background: #fff;
  }
}

.border-gradient-purple {
  border: 1px solid;
  border-image-source: linear-gradient(to left, #0956ff, #5389ff);
}
